
import { defineComponent, PropType, computed } from 'vue'
import { getInterestingCategoryImagePath } from 'theme/helpers'
import { SfButton } from '@storefront-ui/vue';
import { ModalList } from 'theme/store/ui/modals';
import { useStoreHelpers } from 'src/composables/useStore'

interface Category {
  id: number,
  url: string,
  name: string,
  icon: string,
  position: number,
  title: string,
  is_new: boolean
}

interface DeviceInfo {
  isMobile: boolean
}

export default defineComponent({
  name: 'OHomeInterestingCategories',
  components: { SfButton },
  props: {
    categories: {
      type: Array as PropType<Category[]>,
      required: true
    },
    deviceInfo: {
      type: Object as PropType<DeviceInfo>,
      required: true
    }
  },
  setup (props) {
    const { useAction } = useStoreHelpers()

    const sortedCategories = computed(() => {
      const sorted = [...props.categories].sort((a, b) => a.position - b.position)
      return isMobile.value ? sorted.slice(0, 7) : sorted
    })

    const isMobile = computed(() => {
      return props.deviceInfo.isMobile
    })

    const getImgPath = (relativePath: string) => {
      return getInterestingCategoryImagePath(relativePath)
    }
    const openModal = useAction('ui', 'openModal')

    const showAllCategoriesModal = () => {
      openModal({
        name: ModalList.InterestingCategories,
        payload: {
          categories: props.categories
        }
      })
    }

    return {
      sortedCategories,
      getImgPath,
      isMobile,
      showAllCategoriesModal
    }
  }
})
